import { useEffect, useState } from 'react';
import { MonetateTracker } from '@vaimo/monetate/src/helpers';

export const useAddProducts = ({ products }) => {
    const [prevProducts, setPrevProducts] = useState([]);

    useEffect(() => {
        if (!Array.isArray(products) || !products.length || JSON.stringify(prevProducts) === JSON.stringify(products)) {
            return;
        }

        setPrevProducts(products);
        // We need to push data, even if items are the same (backward navigation)
        MonetateTracker.data({
            addProducts: products,
        });
    }, [prevProducts, products]);
};
