import Column, { columnConfigAggregator } from './ContentTypes/Column';
import ColumnGroup, { columnGroupConfigAggregator } from './ContentTypes/ColumnGroup';
import ColumnLine, { columnLineConfigAggregator } from './ContentTypes/ColumnLine';
import Heading, { headingConfigAggregator } from './ContentTypes/Heading';
import Html, { htmlConfigAggregator } from './ContentTypes/Html';
import PageSizeChart, { pageSizeChartConfigAggregator } from './ContentTypes/PageSizeChart';
import Row, { rowConfigAggregator } from './ContentTypes/Row';
import Text, { textConfigAggregator } from './ContentTypes/Text';
import TextOnImage, { textOnImageConfigAggregator } from './ContentTypes/TextOnImage';
import TextReadMore, { textReadMoreConfigAggregator } from './ContentTypes/TextReadMore';
import type { ContentTypes } from './types';

const contentTypesConfig = {
    column: {
        component: Column,
        configAggregator: columnConfigAggregator,
    },
    'column-group': {
        component: ColumnGroup,
        configAggregator: columnGroupConfigAggregator,
    },
    'column-line': {
        component: ColumnLine,
        configAggregator: columnLineConfigAggregator,
    },
    heading: {
        component: Heading,
        configAggregator: headingConfigAggregator,
    },
    html: {
        component: Html,
        configAggregator: htmlConfigAggregator,
    },
    'page-size-chart': {
        component: PageSizeChart,
        configAggregator: pageSizeChartConfigAggregator,
    },
    row: {
        component: Row,
        configAggregator: rowConfigAggregator,
    },
    text: {
        component: Text,
        configAggregator: textConfigAggregator,
    },
    'text-on-image': {
        component: TextOnImage,
        configAggregator: textOnImageConfigAggregator,
    },
    'text-read-more': {
        component: TextReadMore,
        configAggregator: textReadMoreConfigAggregator,
    },
};

export function getContentTypeConfig(contentType: ContentTypes) {
    if (contentTypesConfig[contentType]) {
        return contentTypesConfig[contentType];
    }
}
