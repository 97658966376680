import { useEffect } from 'react';
import { isValidArray, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const usePush = (deps = []) => {
    useEffect(() => {
        if (!isValidArray(deps)) return;

        MonetateTracker.push();
    }, deps);
};
