import { useAddProductDetails, useBreadcrumbs, useCartProducts, usePageType, usePush } from '@vaimo/monetate/src/hooks';
import { useAddCategories, useAddToCart, useCustomVariables } from '@vaimo/monetate/src/talons';
import { PAGE_TYPES } from '@vaimo/monetate/src/integrations';
import { useMonetateContext } from '@vaimo/monetate/src/context/monetate';

export const useProductPage = ({ breadcrumbs, colorValue, productSku }) => {
    const [{ deliveryCountry, isPriceIncludesTax, lang }] = useMonetateContext();
    const { currentCartProducts, isAddedProductToCart } = useAddToCart();
    const deps = [colorValue, currentCartProducts?.length, isAddedProductToCart, productSku];

    useAddCategories({ deliveryCountry, deps, isAddedToCart: isAddedProductToCart, lang });
    useCustomVariables({ deliveryCountry, deps, lang });
    usePageType({ deps, pageType: PAGE_TYPES.PRODUCT });
    useBreadcrumbs({ breadcrumbs, deps });
    useCartProducts({ deps, isPriceIncludesTax, products: currentCartProducts });
    useAddProductDetails({
        deps,
        product: [{ productId: productSku, sku: `${productSku}_${colorValue}` }],
    });
    usePush(deps);
};
