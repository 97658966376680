import React from 'react';
import { collectCssStyles } from '@/components/RichContent/PageBuilder/utils/helpers';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';

export enum HeadingTags {
    DIV = 'div',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

interface IHeading extends CssClassesProps {
    headingType: HeadingTags;
    text: string;
}

const Heading = ({ classes: propsClasses, cssClasses = [], headingType: Component, text, ...rest }: IHeading) => {
    const dynamicStyles = collectCssStyles(rest);

    if (!text) return null;

    return (
        <Component style={dynamicStyles} className={cssClasses.join(' ')}>
            {text}
        </Component>
    );
};

export default Heading;
