import { useEffect } from 'react';
import MonetateTracker from '@vaimo/monetate/src/helpers/monetateTracker';

export const useInitialize = ({ isMonetateEnabled, monetateScriptSrc }) => {
    useEffect(() => {
        if (!isMonetateEnabled || !monetateScriptSrc) return;

        MonetateTracker.initialize({ monetateScriptSrc });
    }, [isMonetateEnabled, monetateScriptSrc]);
};
