import { createActions } from 'redux-actions';

const prefix = 'EMARSYS';
const actionTypes = [
    'SET_CART_DATA',
    'SET_CURRENT_USER_EMAIL',
    'RESET_CART_DATA',
    'RESET_CURRENT_USER_EMAIL',
    'SET_PREVIOUS_PATH',
];

export default createActions(...actionTypes, { prefix });
