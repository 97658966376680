import React, { ReactNode } from 'react';
import clsx from 'clsx';
import mergeClasses from '@/utils/Object/shallowMerge';
import { collectCssStyles, collectFlexCssStyles } from '@/components/RichContent/PageBuilder/utils/helpers';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';
import defaultClasses from './column.module.scss';

interface IColumn extends CssClassesProps {
    children: ReactNode;
}

const Column = ({ children, classes: propsClasses, cssClasses = [], ...rest }: IColumn) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const styles = collectCssStyles(rest);
    const flexStyles = collectFlexCssStyles(rest);

    return (
        <div
            style={{
                ...styles,
                ...flexStyles,
            }}
            className={clsx(classes.root, cssClasses)}
        >
            {children}
        </div>
    );
};

export default Column;
