import React from 'react';
import clsx from 'clsx';
import mergeClasses from '@/utils/Object/shallowMerge';
import { collectCssStyles } from '@/components/RichContent/PageBuilder/utils/helpers';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';
import defaultClasses from './html.module.scss';

interface IHtml extends CssClassesProps {
    dangerouslySetInnerHTML: string;
}

const Html = ({ classes: propsClasses, cssClasses = [], dangerouslySetInnerHTML, ...rest }: IHtml) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const dynamicStyles = collectCssStyles(rest);

    if (!dangerouslySetInnerHTML) return null;

    return (
        <div
            style={dynamicStyles}
            className={clsx(classes.root, cssClasses)}
            dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
            role="presentation"
        />
    );
};

export default Html;
