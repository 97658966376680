'use client';

import React, { ReactNode, createContext } from 'react';
import { useMainLayout } from '../hooks/useMainLayout';

interface IMainLayoutContext {
    loading: boolean;
    startLoading: () => void;
}

export const MainLayoutContext = createContext<IMainLayoutContext>({} as IMainLayoutContext);

export const MainLayoutProvider = ({ children }: { children: ReactNode }) => {
    const values = useMainLayout();

    return <MainLayoutContext.Provider value={values}>{children}</MainLayoutContext.Provider>;
};
