import {
    useCartProducts as useCartProductsHook,
    usePageType as usePageTypeHook,
    usePush,
} from '@vaimo/monetate/src/hooks';
import { useAddCategories, useCartProducts, useCustomVariables } from '@vaimo/monetate/src/talons';
import { useMonetateContext } from '@vaimo/monetate/src/context/monetate';

export const PAGE_TYPES = {
    ACCOUNT: 'account',
    CART: 'cart',
    CATEGORY: 'index',
    CHECKOUT: 'checkout',
    CONTACT_US: 'customerservicecontactform',
    HOME: 'home',
    JOIN: 'signup',
    ORDER_CONFIRMATION: 'purchase',
    ORDER_VIEW: 'orderstatus',
    PAYMENT: 'payment',
    PRODUCT: 'product',
    SEARCH: 'search',
    SHIPPING: 'shipping',
    SIGN_IN: 'login',
    WISHLIST: 'wishlist',
};

export const usePageType = ({ pageType }) => {
    const [{ deliveryCountry, isPriceIncludesTax, lang }] = useMonetateContext();
    const products = useCartProducts();
    const deps = [pageType, products?.length];

    useAddCategories({ deliveryCountry, deps, lang });
    useCustomVariables({ deliveryCountry, deps, lang });
    useCartProductsHook({ deps, isPriceIncludesTax, products });
    usePageTypeHook({ deps, pageType: pageType === 'home' ? 'main' : pageType });
    usePush(deps);
};
