import { useEffect, useMemo, useState } from 'react';
import { useMonetateContext } from '@vaimo/monetate/src/context/monetate';
import { usePrevious } from '@vaimo/monetate/src/hooks';

const getAddedProduct = (prevCart, currentCart) => {
    if (!prevCart || !currentCart) {
        return null;
    }

    const prevItems = prevCart.cart.items;
    const currentItems = currentCart.cart.items;

    for (let currentItem of currentItems) {
        const prevItem = prevItems.find((prevItem) => prevItem.uid === currentItem.uid);

        if (!prevItem) {
            return currentItem;
        }

        if (prevItem.quantity < currentItem.quantity) {
            return {
                ...currentItem,
                quantity: currentItem.quantity - prevItem.quantity,
            };
        }
    }
};

export const useAddToCart = () => {
    const [{ cartDetails }] = useMonetateContext();
    const cartId = cartDetails?.cart?.id || null;
    const [currentCart, setCurrentCart] = useState();
    const [isAddedProductToCart, setIsAddedToCartProduct] = useState(false);
    const prevCartId = usePrevious(cartId);
    const previousCart = usePrevious(currentCart);
    const [isMergingCart, setIsMergingCart] = useState(false);

    useEffect(() => {
        if (!prevCartId) return;

        if (!cartId) {
            if (!isMergingCart) {
                setIsMergingCart(true);
            }
        } else {
            if (isMergingCart && cartId === prevCartId) {
                setIsMergingCart(false);
            }
        }
    }, [prevCartId, cartId, isMergingCart]);

    useEffect(() => {
        if (!cartDetails) return;

        setCurrentCart(cartDetails);
    }, [cartDetails]);

    useEffect(() => {
        if (isMergingCart || (currentCart?.cart && previousCart?.cart?.id !== currentCart.cart.id)) {
            return;
        }

        const addedProduct = getAddedProduct(previousCart, currentCart);

        if (addedProduct) {
            setIsAddedToCartProduct(true);
        }
    }, [previousCart, currentCart, isMergingCart]);

    const currentCartProducts = useMemo(() => currentCart?.cart?.items, [currentCart?.cart?.items]);

    return {
        currentCartProducts,
        isAddedProductToCart,
    };
};
