import { IRegisteredState } from '@/lib/context/appServerState/types';
import { MiddlewareSettingsKeysEnum } from '@/utils/middleware/applySettings';

export type TBotState = typeof defaultState;

const defaultState = {
    isBot: false,
};

const setter = (state: TBotState, settingsValue: number) => {
    if (settingsValue === 1) {
        state.isBot = true;
    }
};

const serverState: IRegisteredState<TBotState> = {
    defaultState,
    id: MiddlewareSettingsKeysEnum.BOT,
    setter,
};

export default serverState;
