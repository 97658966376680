import { useCallback, useEffect, useOptimistic, useState } from 'react';

export const useMainLayout = () => {
    const [loading, setLoading] = useOptimistic(false);
    const [isActiveLoader, setIsActiveLoader] = useState(false);

    const startLoading = useCallback(() => setLoading(true), []);

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => setIsActiveLoader(loading), 300);

            return () => clearInterval(interval);
        } else {
            setIsActiveLoader(false);
        }
    }, [loading]);

    return {
        loading: isActiveLoader,
        startLoading,
    };
};
