import React from 'react';
import parseStorageHtml from '@/components/RichContent/PageBuilder/utils/parseStorageHtml';
import ContentTypesFactory from './ContentTypes/contentTypesFactory';
import { IPageBuilder } from './types';
import { useRequestContext } from '@/lib/context';

const PagebuilderClient = ({ classes, html, ...restProps }: IPageBuilder) => {
    const { storeCode } = useRequestContext();
    if (!html) return null;

    const resolveDOM = (content: string) => new DOMParser().parseFromString(content, 'text/html');

    const data = parseStorageHtml(html, restProps, resolveDOM);

    return data.children.map((child, i) => {
        return (
            <div className={classes?.root} key={i}>
                <ContentTypesFactory data={child} storeCode={storeCode} />
            </div>
        );
    });
};

export default PagebuilderClient;
