import { getAdvanced } from '@/components/RichContent/PageBuilder/utils/helpers';
import { TResolveDomStructure } from '@/components/RichContent/PageBuilder/types';

export default (node: HTMLElement, _: unknown, resolveDOMStructure: TResolveDomStructure) => {
    // the content may contain a script tag and is a vulnerable code
    // @todo to find the solution. Potential solution DOMPurify to sanitize incoming code
    // but it that case we will lose custom implementation/plugin for example https://www.hellyhansen.com/en_us/returns
    let dangerouslySetInnerHTML;
    if (node.dataset.decoded) {
        dangerouslySetInnerHTML = node.innerHTML;

        if (process.env.NODE_ENV !== 'production') {
            console.warn('PageBuilder HTML content was unescaped! This may be a Magento configuration error.');
        }
    } else {
        const document = resolveDOMStructure(node?.textContent || '');

        dangerouslySetInnerHTML = document.body.innerHTML;
    }

    return {
        dangerouslySetInnerHTML,
        ...getAdvanced(node),
    };
};
