import React from 'react';
import clsx from 'clsx';
import mergeClasses from '@/utils/Object/shallowMerge';
import { collectCssStyles } from '@/components/RichContent/PageBuilder/utils/helpers';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';
import defaultClasses from './text.module.scss';

interface IText extends CssClassesProps {
    content: string;
}

const Text = ({ classes: propsClasses, content, cssClasses = [], ...rest }: IText) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const dynamicStyles = collectCssStyles(rest);

    return (
        <div
            style={dynamicStyles}
            className={clsx(classes.root, cssClasses)}
            dangerouslySetInnerHTML={{ __html: content }}
            role="presentation"
        />
    );
};

export default Text;
