'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions/monetate';
import bindActionCreators from '../helpers/bindActionCreators';

const MonetateContext = createContext();

const MonetateContextProvider = ({ actions, children, initState, monetateState }) => {
    const contextValue = useMemo(
        () => [{ ...initState, ...monetateState }, { actions }],
        [actions, initState, monetateState],
    );

    return <MonetateContext.Provider value={contextValue}>{children}</MonetateContext.Provider>;
};

const mapStateToProps = ({ monetate }) => ({ monetateState: monetate });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonetateContextProvider);

export const useMonetateContext = () => useContext(MonetateContext);
