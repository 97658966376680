export const removeFirstSlashFromRelativeUrl = (url: string) => (url.startsWith('/') ? url.slice(1) : url);

export const removeLastSlashFromPathname = (pathname: string) =>
    pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

export const removeLastSlashFromHref = (href: string): string => {
    const url = new URL(href);
    url.pathname = removeLastSlashFromPathname(url.pathname);

    return url.toString();
};

export const removeStoreCodeFromUrl = (url: string): string => {
    return url.match(/^(\/\w{2,2}_\w+(_ww)?)?(.*)/)?.[3] || url;
};

export const addFirstSlashToRelativeUrl = (url: string) => (url.startsWith('/') ? url : '/' + url);

export const addStoreCodeToPathname = (pathname: string, storeCode: string) =>
    `/${storeCode}${pathname.startsWith('/') ? pathname : '/' + pathname}`;

const storeCodePattern = new RegExp('^[a-zA-Z]{2}_([a-zA-Z]{2}|global)(_ww)?$');

export const isPathnameWithStoreCode = (url: string): boolean => {
    const firstPart = url.split('/', 2)[1];

    return storeCodePattern.test(firstPart);
};
