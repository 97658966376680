import { useEffect } from 'react';
import { getPriceWithOrWithoutTax, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const useAddPurchaseRows = ({ isPriceIncludesTax, orderDetailsData, orderNumber }) => {
    useEffect(() => {
        if (!orderDetailsData?.cart?.items || !orderNumber) return;

        MonetateTracker.data({
            addPurchaseRows: orderDetailsData.cart.items.map((item) => ({
                currency: item.product.price_range.minimum_price.final_price.currency,
                productId: item.product?.sku,
                purchaseId: orderNumber,
                quantity: item.quantity,
                sku: item.configured_variant?.sku.split('-')[0] || item.product?.sku,
                unitPrice: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
            })),
        });
    }, [isPriceIncludesTax]);
};
