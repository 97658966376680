import { NextRequest } from 'next/server';
import { encodeSettings } from '@/lib/context/appServerState/utils/settingsParser';

/**
 * Since setting values will be used in http headers by next.js,
 * we need to keep small and avoid usage special characters.
 *
 * Key and value of the settings should always be a positive integers.
 * Key and value will be separated by "~" in settings string.
 * Different settings will be separated by "." in settings string.
 *
 * Example of settings string: "1~1.2~1.3~11"
 */

export type TSettingsObject = Record<MiddlewareSettingsKeysEnum, number>;

export enum MiddlewareSettingsKeysEnum {
    A_B_TESTING = 1,
    BOT = 2,
}

export const applySettings = (request: NextRequest, settings: TSettingsObject) => {
    const settingsString = encodeSettings(settings);

    const [, storeCode, ...restPaths] = request.nextUrl.pathname.split('/');

    const newPathname = '/' + [storeCode, settingsString, ...restPaths].join('/');
    request.nextUrl.pathname = newPathname;
};
