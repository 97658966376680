'use client';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import Text from '../Text';
import classes from './textReadMore.module.scss';

interface ITextReadMore {
    hiddenText: string;
    visibleText: string;
}

const TextReadMore = ({ hiddenText, visibleText }: ITextReadMore) => {
    const [showHiddenText, setShowHiddenText] = useState(false);

    const t = useTranslations('global');
    const content = showHiddenText ? `${visibleText} ${hiddenText}` : visibleText;

    return (
        <div className={classes.container}>
            <Text cssClasses={[classes.textContent]} content={content} />
            <button className={classes.readMoreButton} onClick={() => setShowHiddenText((state) => !state)}>
                {showHiddenText ? t('readLess') : t('readMore')}
            </button>
        </div>
    );
};

export default TextReadMore;
