import React, { ReactNode } from 'react';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './columnLine.module.scss';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';

interface IColumnLine extends CssClassesProps {
    children: ReactNode;
    display?: string;
}

const ColumnLine = ({ children, classes: propsClasses, display }: IColumnLine) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const dynamicStyles = { display };

    return (
        <div style={dynamicStyles} className={classes.root}>
            {children}
        </div>
    );
};

export default ColumnLine;
