import { cache } from 'react';
import { decodeSettings, DEFAULT_EMPTY_SETTINGS } from './utils/settingsParser';
import { registeredStates, TRegisteredStates } from './utils/registeredStates';
import getAvailableStores from '@/modules/adobe-commerce-webpack/getAvailableStores';
import { notFound } from 'next/navigation';

interface IDefaultState extends TRegisteredStates {
    storeCode: string;
}

export const getAppServerState = cache(() => {
    const defaultState = { storeCode: '' } as IDefaultState;

    return registeredStates.reduce(
        (accumulated, item) => ({ ...accumulated, ...item.defaultState }),
        defaultState as Readonly<IDefaultState>,
    );
});

export const initAppServerState = ({
    settings,
    storeCode,
}: {
    settings: string;
    storeCode: string;
}): Readonly<IDefaultState> => {
    const serverState = getAppServerState();

    // We are checking storeCode to see if serverState got already initialized.
    if (serverState.storeCode) return serverState;

    if (getAvailableStores().some((store) => store.code === storeCode)) {
        // @ts-expect-error Readonly only for application validation
        serverState.storeCode = storeCode;
    } else {
        notFound();
    }

    // Check if settings are empty
    if (settings === DEFAULT_EMPTY_SETTINGS) return serverState;

    const settingsObject = decodeSettings(settings);

    Object.entries(settingsObject).forEach(([key, value]) => {
        const proceedItem = registeredStates.find((item) => item.id === +key);
        if (proceedItem) {
            proceedItem.setter(serverState, value);
        }
    });

    return serverState;
};
