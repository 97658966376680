import React from 'react';

/**
 * Overwrite LazyLoadImage component from react-lazy-load-image-component package,
 * which is used by aem-pwa-common package to support SSR rendering
 */

interface ILazyLoadImage extends HTMLImageElement {
    effect?: string;
    placeholderSrc?: string;
    wrapperClassName?: string;
}

const sanitizeRestProps = (props: ILazyLoadImage): HTMLImageElement => {
    const validImgAttr = ['src', 'alt', 'srcSet', 'sizes', 'width', 'height', 'onClick', 'onFocus'];

    const filteredProps = Object.entries(props).reduce(
        (accumulated, [key, value]) => {
            if (key && validImgAttr.includes(key)) {
                accumulated[key] = value;
            }

            return accumulated;
        },
        {} as Record<string, any>,
    );

    return filteredProps as HTMLImageElement;
};

const LazyLoadImage = (props: ILazyLoadImage) => {
    return (
        <div className={props.wrapperClassName}>
            {/* @ts-expect-error Force overwrite */}
            <img
                // @ts-expect-error Force overwrite
                loading="lazy"
                // @ts-expect-error Force overwrite
                decoding={props.fetchPriority === 'high' ? 'sync' : 'async'}
                {...sanitizeRestProps(props)}
            />
        </div>
    );
};

export default LazyLoadImage;
