export default (element) => {
    const { attributes, tagName } = element;
    const replacementElement = document.createElement(tagName);

    for (const { name, value } of attributes) {
        replacementElement.setAttribute(name, value);
    }

    return replacementElement;
};
