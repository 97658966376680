import { useEffect } from 'react';
import { isValidArray, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const useCustomVariables = ({ deliveryCountry, deps, lang }) => {
    useEffect(() => {
        if (!isValidArray(deps)) return;

        MonetateTracker.data({
            setCustomVariables: [
                { name: 'deliveryCountry', value: deliveryCountry },
                { name: 'lang', value: lang },
            ],
        });
    }, deps);
};
