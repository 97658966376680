export default {
    data: (data) => {
        const entries = data && typeof data === 'object' ? Object.entries(data) : [];

        globalThis.monetateQ = globalThis.monetateQ || [];
        if (entries.length && globalThis.monetateQ) {
            entries.forEach((entry) => globalThis.monetateQ.push(entry));
        }
    },
    initialize: ({ monetateScriptSrc }) => {
        if (!monetateScriptSrc || !typeof monetateScriptSrc === 'string') return;

        globalThis.window.monetateT = new Date().getTime();

        const monetateSourceScript = document.createElement('script');
        monetateSourceScript.type = 'text/javascript';
        monetateSourceScript.src = monetateScriptSrc;

        globalThis.document.head.appendChild(monetateSourceScript);
    },
    push: () => {
        if (globalThis.monetateQ) {
            globalThis.monetateQ.push(['trackData']);
        }
    },
};
