import { useAddCategories as useAddCategoriesHook } from '@vaimo/monetate/src/hooks';

export const useAddCategories = ({ deliveryCountry, deps, isAddedToCart, lang }) => {
    const deliveryCountryCategory = `deliveryCountry_${deliveryCountry?.toUpperCase()}`;
    const langCategory = `lang_${lang?.toLowerCase()}`;

    const categories = isAddedToCart
        ? [deliveryCountryCategory, langCategory, 'addedToCart']
        : [deliveryCountryCategory, langCategory];

    useAddCategoriesHook({ categories, deps });
};
