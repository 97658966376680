import React, { ReactElement } from 'react';
import { Typography } from '@vaimo-int/aem-pwa-common-components';

interface IPlainHtmlRenderer {
    classes: {
        [className: string]: string;
    };
    html?: string;
}

const PlainHtmlRenderer = ({ classes, html }: IPlainHtmlRenderer): ReactElement | null => {
    if (!html) {
        return null;
    }

    return <Typography.Richtext className={classes.root} html={html} />;
};

export default PlainHtmlRenderer;
