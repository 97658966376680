import React from 'react';
import parseStorageHtml from '@/components/RichContent/PageBuilder/utils/parseStorageHtml';
import ContentTypesFactory from './ContentTypes/contentTypesFactory';
import { IPageBuilder } from './types';
import getAppServerState from '@/lib/context/appServerState';

const PagebuilderServer = async ({ classes, html, ...restProps }: IPageBuilder) => {
    if (!html) return null;

    const { JSDOM }: any = await import('jsdom');
    const { storeCode } = getAppServerState();

    const resolveDOM = (content: string) => {
        const { window } = new JSDOM(`<!DOCTYPE html>${content}`);
        const { document } = window;

        return document;
    };

    const data = parseStorageHtml(html, restProps, resolveDOM);

    return data.children.map((child, i) => {
        return (
            <div className={classes?.root} key={i}>
                <ContentTypesFactory data={child} storeCode={storeCode} />
            </div>
        );
    });
};

export default PagebuilderServer;
