import React, { ReactElement } from 'react';
import mergeClasses from '@/utils/Object/shallowMerge';
import richContentRenderers from '@/components/RichContent/richContentRenderers';
import { IPageBuilder } from '@/components/RichContent/PageBuilder/types';
import richContentClasses from './richContent.module.scss';

const RichContent = ({ classes: propsClasses, html, ...restProps }: IPageBuilder): ReactElement | null => {
    const classes = mergeClasses(richContentClasses, propsClasses);

    for (const Renderer of richContentRenderers) {
        const { Component, canRender } = Renderer;

        if (canRender(html || null)) {
            return <Component classes={classes} html={html} {...restProps} />;
        }
    }

    // If no renderer returned a value
    if (process.env.NODE_ENV === 'development') {
        console.warn(
            `None of the following rich content renderers returned anything for the provided HTML.`,
            richContentRenderers.map((r) => `<${r.name}>`),
            html,
        );
    }

    return null;
};

export default RichContent;
