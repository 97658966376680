import { useEffect } from 'react';
import { isValidArray, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const useBreadcrumbs = ({ breadcrumbs, deps }) => {
    useEffect(() => {
        if (!breadcrumbs?.length || !isValidArray(deps)) {
            return;
        }

        MonetateTracker.data({
            addBreadcrumbs: breadcrumbs,
        });
    }, deps);
};
