import { MiddlewareSettingsKeysEnum } from '@/utils/middleware/applySettings';
import { IRegisteredState } from '@/lib/context/appServerState/types';

export type TAbTestingState = typeof defaultState;

const defaultState = {
    isAB: false,
};

const setter = (state: TAbTestingState, settingsValue: number) => {
    if (settingsValue === 1) {
        state.isAB = true;
    }
};

const serverState: IRegisteredState<TAbTestingState> = {
    defaultState,
    id: MiddlewareSettingsKeysEnum.A_B_TESTING,
    setter,
};

export default serverState;
