'use client';

import { useContext } from 'react';
import { MainLayoutContext } from '../context/mainLayoutContext';

export const useProgress = (context = MainLayoutContext) => {
    const { startLoading } = useContext(context);

    return {
        startLoading,
    };
};
