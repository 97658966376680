export interface IPageBuilder {
    classes?: {
        [className: string]: string;
    };
    html?: string | null;
    identifier?: string;
    type?: string;
}

export type TResolveDomStructure = (content: string) => Document;

export type ContentTypeStructure = {
    appearance: any;
    children: unknown[];
    contentType: string;
};

export enum VerticalAlignmentEnum {
    BOTTOM = 'bottom',
    MIDDLE = 'middle',
    TOP = 'top',
}

export enum AppearanceEnum {
    CONTAINED = 'contained',
    FULLBLEED = 'full-bleed',
    FULLWIDTH = 'full-width',
}

export type BorderProps = {
    border: string;
    borderColor: string;
    borderRadius: string;
    borderWidth: string;
};

export type MarginProps = {
    marginBottom: string;
    marginLeft: string;
    marginRight: string;
    marginTop: string;
};

export type PaddingProps = {
    paddingBottom: string;
    paddingLeft: string;
    paddingRight: string;
    paddingTop: string;
};

export type TextAlignProps = {
    textAlign: string;
};

export type CssClassesProps = {
    classes?: {
        [className: string]: string;
    };
    cssClasses?: string[];
};

export type MediaResponseItem = {
    media?: string;
    style: object;
};

type BackgroundProps = {
    backgroundAttachment?: string;
    backgroundColor?: string;
    backgroundPosition?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    backgroundType?: string;
    desktopImage?: string;
    minHeight?: string;
    mobileImage?: string;
};

export type ContentStyleProps = {
    backgroundAttachment?: string;
    backgroundColor?: string;
    backgroundPosition?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    backgroundType?: string;
    desktopImage?: string;
    minHeight?: string;
    mobileImage?: string;
    verticalAlignment?: VerticalAlignmentEnum;
    videoFallbackSrc?: string;
    videoLazyLoading?: string;
    videoLoop?: string;
    videoOverlayColor?: string;
    videoPlayOnlyVisible?: string;
    videoSrc?: string;
};

export interface DynamicStyles extends BorderProps, MarginProps, PaddingProps, BackgroundProps {
    display?: string;
    flexDirection?: string;
    justifyContent?: string;
}

export type ContentTypes = 'html' | 'row' | 'text';
