import {
    getAdvanced,
    getBackgroundImages,
    getVerticalAlignment,
    getMediaQueries,
} from '@/components/RichContent/PageBuilder/utils/helpers';

export default (node: HTMLElement) => ({
    backgroundColor: node.style.backgroundColor,
    display: node.style.display,
    minHeight: node.style.minHeight ? node.style.minHeight : null,
    width: node.style.width,
    ...getMediaQueries(node),
    ...getAdvanced(node),
    ...getBackgroundImages(node),
    ...getVerticalAlignment(node),
});
