/* eslint-disable @typescript-eslint/member-ordering */
class WidgetConfigurationExtractor {
    node: HTMLElement;

    constructor(node: HTMLElement) {
        this.node = node;

        this.unescapeHtmlTags();
        this.removeUnnecessaryElements();
        this.unwrapContentType();
    }

    unescapeHtmlTags() {
        this.node.querySelectorAll('*').forEach((element) => {
            element.innerHTML = element.innerHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        });
    }

    unwrapContentType() {
        const selector = '[data-content-type], [data-content-type="row"] > [data-element]';

        this.node.querySelectorAll(selector).forEach((element) => element.replaceWith(...element.childNodes));
    }

    removeUnnecessaryElements() {
        this.node.querySelectorAll('style').forEach((element) => element.remove());
        this.node.querySelectorAll('p').forEach((element) => element.innerHTML.trim() || element.remove());
    }

    getConfig() {
        return {
            html: this.node.innerHTML,
        };
    }
}

export default (node: HTMLElement) => new WidgetConfigurationExtractor(node).getConfig();
