import { textReadMoreConfigAggregator } from '../ContentTypes/TextReadMore';

type SvgPlaceholderDimensions = {
    height: string | number;
    width: string | number;
};

const SELECTOR_READ_MORE = '.b-pb-text__container[data-content-type]';
const SELECTOR_TEXT_ON_IMAGE = '.widget__text-on-image';

const getSvgPlaceholderDimensions = (element: HTMLImageElement): SvgPlaceholderDimensions | void => {
    const src = element.getAttribute('src');
    const prefix = 'data:image/svg+xml;base64,';
    const viewBoxRegex = /viewBox="([^"]+)"/;

    if (!src?.startsWith(prefix)) return;

    try {
        const svgHtml = atob(src.slice(prefix.length));
        const viewBoxMatch = svgHtml.match(viewBoxRegex);

        if (viewBoxMatch) {
            const viewBoxValue = viewBoxMatch?.[1]?.split(' ').slice(2);

            return {
                height: viewBoxValue[1],
                width: viewBoxValue[0],
            };
        }
    } catch (e) {}
};

export const customContentType = (container: Document) => {
    // TextReadMore
    if (container.querySelectorAll(SELECTOR_READ_MORE)) {
        container.querySelectorAll(SELECTOR_READ_MORE).forEach((element: any) => {
            const content = textReadMoreConfigAggregator(element);

            if (content.visibleText && content.hiddenText) {
                element.setAttribute('data-content-type', 'text-read-more');
            }
        });
    }

    // TextOnImage
    if (container.querySelectorAll(SELECTOR_TEXT_ON_IMAGE)) {
        container.querySelectorAll(SELECTOR_TEXT_ON_IMAGE).forEach((element) => {
            element.setAttribute('data-content-type', 'text-on-image');
            const parent = element.parentElement;

            if (parent && parent.getAttribute('data-content-type') === 'text') {
                parent.replaceWith(element);
            }
        });
    }

    // Image base64 format
    if (container.querySelectorAll('img[data-mage-init][src^="data:"]')) {
        container.querySelectorAll('img[data-mage-init][src^="data:"]').forEach((element: any) => {
            const mageData = element.dataset.mageInit;

            if (!mageData) return;

            const { replaceImagePlaceholder } = JSON.parse(mageData);

            if (replaceImagePlaceholder?.image) {
                Object.assign(element, {
                    ...getSvgPlaceholderDimensions(element),
                    src: replaceImagePlaceholder.image,
                });
            }

            element.removeAttribute('data-mage-init');
        });
    }
};
