import React, { ReactNode } from 'react';
import mergeClasses from '@/utils/Object/shallowMerge';
import type { CssClassesProps } from '@/components/RichContent/PageBuilder/types';
import defaultClasses from './columnGroup.module.scss';

interface IColumnGroup extends CssClassesProps {
    children: ReactNode;
    display?: string;
}

const ColumnGroup = ({ children, classes: propsClasses, display }: IColumnGroup) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const dynamicStyles = {
        display,
    };

    return (
        <div style={dynamicStyles} className={classes.root}>
            {children}
        </div>
    );
};

export default ColumnGroup;
