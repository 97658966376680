import {
    useAddProducts,
    useBreadcrumbs,
    useCartProducts as useCartProductsHook,
    usePageType,
    usePush,
} from '@vaimo/monetate/src/hooks';
import { useAddCategories, useCartProducts, useCustomVariables } from '@vaimo/monetate/src/talons';
import { useMonetateContext } from '@vaimo/monetate/src/context/monetate';

export const useProductListingPage = ({ breadcrumbs, pageType, products }) => {
    const [{ deliveryCountry, isPriceIncludesTax, lang }] = useMonetateContext();
    const cartProducts = useCartProducts();
    const categoryProducts = products?.map(({ sku }) => sku) || null;
    const deps = [cartProducts?.length, products?.length, pageType];

    useAddCategories({ deliveryCountry, deps, lang });
    useCustomVariables({ deliveryCountry, deps, lang });
    usePageType({ deps, pageType });
    useBreadcrumbs({ breadcrumbs, deps });
    useCartProductsHook({ deps, isPriceIncludesTax, products: cartProducts });
    useAddProducts({ products: categoryProducts });
    usePush(deps);
};
