import React from 'react';
import Link from '@/components/Link';
import getSplittedChunk from '@vaimo-int/aem-pwa-common-components/dist/utils/getSplittedChunk';
import verifyLeadingChar from '@vaimo-int/aem-pwa-common-components/dist/utils/verifyLeadingChar';

/**
 * Overwrite AemLink component, which is used by aem-pwa-common package to support NextJs links
 */

const AemLink = ({ children, className, onClick, to, ...rest }) => {
    if (!to) return children;

    if (to && typeof to === 'object') {
        return (
            <Link href={to.pathname} onClick={onClick} className={className} {...rest}>
                {children}
            </Link>
        );
    }

    const splittedChunk = typeof to === 'string' ? getSplittedChunk(to, 1) : '';
    const href = verifyLeadingChar(to);

    if (['dam', 'content'].includes(splittedChunk)) {
        return (
            <a href={href} className={className} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <Link href={href} onClick={onClick} className={className} {...rest}>
            {children}
        </Link>
    );
};

export default AemLink;
