import { useAddPurchaseRows, usePageType, usePush } from '@vaimo/monetate/src/hooks';
import { useAddCategories, useCustomVariables } from '@vaimo/monetate/src/talons';
import { PAGE_TYPES } from '@vaimo/monetate/src/integrations';
import { useMonetateContext } from '@vaimo/monetate/src/context/monetate';

export const useOrderConfirmationPage = ({ orderDetailsData, orderNumber }) => {
    const [{ deliveryCountry, isPriceIncludesTax, lang }] = useMonetateContext();
    const deps = [deliveryCountry, orderDetailsData, orderNumber];

    useAddCategories({ deliveryCountry, deps, lang });
    useCustomVariables({ deliveryCountry, deps, lang });
    usePageType({ deps, pageType: PAGE_TYPES.ORDER_CONFIRMATION });
    useAddPurchaseRows({ isPriceIncludesTax, orderDetailsData, orderNumber });
    usePush(deps);
};
