import type { ImageData, SourceData } from './types';

class WidgetConfigurationExtractor {
    private readonly defaultDescriptionAlignment = 'center';
    private readonly defaultVerticalAlignment = 'middle';
    private node: HTMLDivElement;
    private readonly selector = {
        button: '.widget__button',
        content: '.widget__content',
        contentWrapper: '.widget__wrapper-content',
        description: '.widget__description',
        image: '.widget__image',
        imageContent: '.widget__image-content',
        imageHasClick: 'widget__image-has-click',
        title: '.widget__title',
        titlePrefix: '.widget__titleprefix',
        video: '.widget__video',
        widget: '.widget__text_on_image',
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    constructor(node: HTMLDivElement) {
        this.node = node;
    }

    extractAlignment(alignments: string[]) {
        const { classList } = this.node.querySelector(this.selector.contentWrapper) || {};

        return classList && alignments.find((alignment: string) => classList.contains(`content-position-${alignment}`));
    }

    extractDescriptionAlignment(alignments: string[]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const nodes = this.node.querySelectorAll([this.selector.content, this.selector.description]);

        for (const { classList } of nodes) {
            for (const alignment of alignments) {
                if (classList.contains(`alignment-${alignment}`)) {
                    return alignment;
                }
            }
        }
    }

    extractTextContent(selector: string) {
        return this.node.querySelector(selector)?.textContent?.trim() || '';
    }

    getButtons() {
        return [...this.node.querySelectorAll(this.selector.button)].map((button) => ({
            href: (button.getAttribute('href') || '').replace('[[base_url]]', '/'),
            textContent: button.textContent,
        }));
    }

    getDescription() {
        return this.extractTextContent(this.selector.description);
    }

    getDescriptionAlignment() {
        const alignments = ['left', 'center', 'right', 'justify'];

        return this.extractDescriptionAlignment(alignments) || this.defaultDescriptionAlignment;
    }

    getElementAttributes(element: Element, attributes: string | string[], defaultValue: any = null) {
        return [attributes].flat().reduce((collection: any, attribute: string) => {
            collection[attribute] = element?.getAttribute(attribute.toLowerCase()) ?? defaultValue;

            return collection;
        }, {});
    }

    getFontSize() {
        return this.node.querySelector('.font-size-big') ? 'big' : 'default';
    }

    getHorizontalAlignment() {
        const alignments = ['left', 'center', 'right', 'justify'];

        return this.extractAlignment(alignments) || this.defaultVerticalAlignment;
    }

    getLinkOptions() {
        const [{ image }] = this.getPictures();
        const buttons = this.getButtons();

        const { alt, hasClick, url } = image || {};
        const { href } = (buttons?.length < 2 && buttons[0]) || {};

        return {
            hasClick,
            label: alt,
            link: url || href,
        };
    }

    getPictures() {
        const getImageData = (element: HTMLDivElement) => {
            const imgElement = element.querySelector<HTMLImageElement>(this.selector.image);

            if (!imgElement) return null;

            return {
                ...this.getElementAttributes(imgElement, ['alt', 'src', 'width', 'height'], ''),
                hasClick: imgElement.classList.contains(this.selector.imageHasClick),
                url: imgElement.parentElement?.getAttribute('href') || '',
            } as ImageData;
        };
        const getSources = (element: HTMLDivElement) =>
            [...element.querySelectorAll<HTMLSourceElement>('picture source')].map((source) =>
                this.getElementAttributes(source, ['media', 'srcSet']),
            ) as SourceData[];

        const elements = [...this.node.querySelectorAll(this.selector.imageContent)] as HTMLDivElement[];

        return elements.filter(getImageData).map((element) => ({
            image: getImageData(element),
            sources: getSources(element),
        }));
    }

    getTitle() {
        return this.extractTextContent(this.selector.title);
    }

    getTitlePrefix() {
        return this.extractTextContent(this.selector.titlePrefix);
    }

    getVerticalAlignment() {
        const alignments = ['top', 'bottom', 'middle'];

        return this.extractAlignment(alignments) || this.defaultVerticalAlignment;
    }

    getVideos() {
        return [...this.node.querySelectorAll(this.selector.video)].map((element) =>
            this.getElementAttributes(element, ['src', 'width', 'height']),
        );
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    getConfig() {
        return {
            buttons: this.getButtons(),
            description: this.getDescription(),
            descriptionAlignment: this.getDescriptionAlignment(),
            fontSize: this.getFontSize(),
            horizontalAlignment: this.getHorizontalAlignment(),
            linkOptions: this.getLinkOptions(),
            pictures: this.getPictures(),
            title: this.getTitle(),
            titlePrefix: this.getTitlePrefix(),
            verticalAlignment: this.getVerticalAlignment(),
            videos: this.getVideos(),
        };
    }
}

export default (node: HTMLDivElement) => new WidgetConfigurationExtractor(node).getConfig();
