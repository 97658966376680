import { useEffect } from 'react';
import { getPriceWithOrWithoutTax, isValidArray, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const useCartProducts = ({ deps, isPriceIncludesTax, products }) => {
    useEffect(() => {
        if (!products?.length || !isValidArray(deps)) return;

        MonetateTracker.data({
            addCartRows: products.map((item) => ({
                currency: item.prices.price.currency,
                productId: item.product?.sku,
                quantity: item.quantity,
                sku: item.configured_variant?.sku.split('-')[0] || item.product?.sku,
                unitPrice: getPriceWithOrWithoutTax(item, isPriceIncludesTax),
            })),
        });
    }, deps);
};
