import type {
    BorderProps,
    CssClassesProps,
    MarginProps,
    MediaResponseItem,
    PaddingProps,
    TextAlignProps,
    VerticalAlignmentEnum,
} from '../types';

const alignmentToFlex = {
    bottom: 'flex-end',
    middle: 'center',
    top: 'flex-start',
};

export const isHTMLElement = (node: Node): boolean => node.nodeType === 1;

export const getIsHidden = (node: HTMLElement): { isHidden: boolean } => ({
    isHidden: node.style.display === 'none',
});

export const getBackgroundImages = (node: HTMLElement) => {
    const images = node.getAttribute('data-background-images');
    const response = {
        backgroundAttachment: node.style.backgroundAttachment,
        backgroundPosition: node.style.backgroundPosition,
        backgroundRepeat: node.style.backgroundRepeat || 'repeat',
        backgroundSize: node.style.backgroundSize,
        desktopImage: null,
        mobileImage: null,
    };

    if (images) {
        const imagesStructure = JSON.parse(images.replace(/\\"/g, '"'));
        const { desktop_image, mobile_image } = imagesStructure || {};

        if (desktop_image) {
            response.desktopImage = desktop_image;
        }

        if (mobile_image) {
            response.mobileImage = mobile_image;
        }
    }

    return response;
};

export const verticalAlignmentToFlex = (alignment: VerticalAlignmentEnum): string => alignmentToFlex[alignment];

export const flexToVerticalAlignment = (flex: string): string => {
    const flexToAlignment = Object.assign({}, ...Object.entries(alignmentToFlex).map(([a, b]) => ({ [b]: a })));

    return flexToAlignment[flex];
};

export const getVerticalAlignment = (node: HTMLElement) => {
    let verticalAlignment = null;

    if (node.style.justifyContent) {
        verticalAlignment = flexToVerticalAlignment(node.style.justifyContent);
    }

    return {
        verticalAlignment,
    };
};

export const collectCssStyles = (data: { [name: string]: string }): object => {
    const {
        border,
        borderColor,
        borderRadius,
        borderWidth,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        textAlign,
        width,
    } = data;

    return {
        border,
        borderColor,
        borderRadius,
        borderWidth,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        textAlign,
        width,
    };
};

export const collectFlexCssStyles = (data: { [name: string]: string }): object => {
    const { appearance, minHeight, verticalAlignment } = data;
    let alignSelf;

    switch (appearance) {
        case 'align-top':
            alignSelf = 'flex-start';
            break;
        case 'align-center':
            alignSelf = 'center';
            break;
        case 'align-bottom':
            alignSelf = 'flex-end';
            break;
        case 'full-height':
        default:
            alignSelf = 'stretch';
            break;
    }

    let justifyContent;

    switch (verticalAlignment) {
        case 'middle':
            justifyContent = 'center';
            break;
        case 'bottom':
            justifyContent = 'flex-end';
            break;
        case 'top':
        default:
            justifyContent = 'flex-start';
            break;
    }

    return {
        alignSelf,
        justifyContent,
        minHeight,
        verticalAlignment,
    };
};

export const getPadding = (node: HTMLElement): PaddingProps => ({
    paddingBottom: node.style.paddingBottom,
    paddingLeft: node.style.paddingLeft,
    paddingRight: node.style.paddingRight,
    paddingTop: node.style.paddingTop,
});

export const getMargin = (node: HTMLElement): MarginProps => ({
    marginBottom: node.style.marginBottom,
    marginLeft: node.style.marginLeft,
    marginRight: node.style.marginRight,
    marginTop: node.style.marginTop,
});

export const getBorder = (node: HTMLElement): BorderProps => ({
    border: node.style.borderStyle,
    borderColor: node.style.borderColor,
    borderRadius: node.style.borderRadius,
    borderWidth: node.style.borderWidth,
});

export const getTextAlign = (node: HTMLElement): TextAlignProps => ({ textAlign: node.style.textAlign });

export const getCssClasses = (node: HTMLElement): CssClassesProps => ({
    cssClasses: node.getAttribute('class') ? node.getAttribute('class')?.split(' ') : [],
});

export const getAdvanced = (node: HTMLElement) => ({
    ...getBorder(node),
    ...getCssClasses(node),
    ...getIsHidden(node),
    ...getMargin(node),
    ...getPadding(node),
    ...getTextAlign(node),
});

export const cssToJSXStyle = (style: string) => {
    const toCamelCase = (str: string) => str.replace(/-(.)/g, (_, p) => p.toUpperCase());
    const result: Record<string, string> = {};

    style.split(';').forEach((el) => {
        const [prop, value] = el.split(':');
        if (prop) {
            result[toCamelCase(prop.trim())] = value.trim();
        }
    });

    return result;
};

export const getMediaQueries = (node: HTMLElement) => {
    const response: MediaResponseItem[] = [];
    const dataset = Object.keys(node.dataset);
    const medias = dataset.filter((key) => key.match(/media-/)).map((key) => node.dataset[key]);
    const styles = dataset.filter((key) => key.match(/mediaStyle/)).map((key) => node.dataset[key]);

    if (!medias?.length) return {};

    medias.forEach((media, i) => {
        const style = styles[i] || '';
        response.push({
            media,
            style: cssToJSXStyle(style),
        });
    });

    return { mediaQueries: response };
};
