import {
    getAdvanced,
    getBackgroundImages,
    getVerticalAlignment,
    getIsHidden,
    getMediaQueries,
} from '@/components/RichContent/PageBuilder/utils/helpers';
import { AppearanceEnum } from '@/components/RichContent/PageBuilder/types';

export default (node: HTMLElement, { appearance }: { appearance: AppearanceEnum }) => {
    const videoOverlayColor =
        appearance === AppearanceEnum.FULLWIDTH || appearance === AppearanceEnum.FULLBLEED
            ? node.getAttribute('data-video-overlay-color')
            : '';

    const minHeight = node.style.minHeight ?? null;

    return {
        minHeight,
        ...getVerticalAlignment(node),
        backgroundColor: node.style.backgroundColor ?? null,
        ...getBackgroundImages(node),
        backgroundType: node.getAttribute('data-background-type'),
        enableParallax: node.getAttribute('data-enable-parallax') === '1',
        videoFallbackSrc: node.getAttribute('data-video-fallback-src'),
        videoLazyLoading: node.getAttribute('data-video-lazy-load') === 'true',
        videoLoop: node.getAttribute('data-video-loop') === 'true',
        videoOverlayColor: videoOverlayColor || null,
        videoPlayOnlyVisible: node.getAttribute('data-video-play-only-visible') === 'true',
        videoSrc: node.getAttribute('data-video-src'),
        ...getAdvanced(node),
        ...getIsHidden(node),
        ...getMediaQueries(node),
    };
};
