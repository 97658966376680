'use client';

import React from 'react';
import { HeroImage2 as HeroImage } from '@vaimo-int/aem-pwa-common-components';
import type { IButton, IVideo, ITextOnImage } from './types';
import classes from './textOnImage.module.scss';

const TextOnImage = ({
    buttons,
    description,
    descriptionAlignment,
    linkOptions,
    pictures,
    title,
    videos,
}: ITextOnImage) => {
    if (!pictures?.length && !title && !description) return null;

    const imageProps = pictures?.map(({ image, sources }) => ({
        alt: image.alt,
        fileReference: image.src,
        fileReferenceMobile: sources[0]?.srcSet || image.src,
        height: image.height,
        url: image.url || linkOptions.link,
        width: image.width,
    }));

    const buttonsProps = buttons?.map((button: IButton) => ({
        children: button.textContent,
        key: button.textContent,
        to: button.href,
        variant: 'secondary',
    }));

    return (
        <div className={classes.root} data-mt-type="text-on-image">
            <HeroImage
                image={imageProps[0]}
                buttons={buttonsProps}
                description={description}
                heading={{ text: title }}
                video={videos?.map((video: IVideo) => video.src)}
                textAlignment={descriptionAlignment}
            />
        </div>
    );
};

export default TextOnImage;
