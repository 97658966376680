import { useEffect } from 'react';
import { isValidArray, MonetateTracker } from '@vaimo/monetate/src/helpers';

export const usePageType = ({ deps, pageType }) => {
    useEffect(() => {
        if (!pageType || !isValidArray(deps)) return;

        MonetateTracker.data({
            setPageType: pageType,
        });
    }, deps);
};
