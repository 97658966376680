import PageBuilder from '@/components/RichContent/PageBuilder';
import PlainHtmlRenderer from '@/components/RichContent/plainHtmlRenderer';
import { detectPageBuilder } from '@/components/RichContent/PageBuilder/utils/detectPageBuilder';

type TRichContentType = {
    Component: any;
    canRender: (content: string | null) => boolean;
    name: string;
};

export default [
    {
        Component: PageBuilder,
        canRender: detectPageBuilder,
        name: 'PageBuilder',
    },
    {
        Component: PlainHtmlRenderer,
        canRender: () => true,
        name: 'PlainHtmlRenderer',
    },
] as TRichContentType[];
