'use client';

import React, { AnchorHTMLAttributes, ReactElement, ReactNode, startTransition } from 'react';
import NextLink, { type LinkProps } from 'next/link';
import { useProgress } from '@/components/MainLayout/hooks/useProgress';
import { useRequestContext } from '@/lib/context';
import { useSelectPromotion } from '@vaimo/google-tag-manager';
import { addStoreCodeToPathname, isPathnameWithStoreCode, removeLastSlashFromPathname } from '@/utils/url';

export interface ILinkProps extends Omit<LinkProps, 'prefetch'>, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    children: ReactNode;
    className?: string;
    context?: any;
    ignoreStoreCode?: boolean;
    prefetch?: boolean | string;
    role?: string;
    tabIndex?: number;
    title?: string;
}

const Link = ({
    children,
    context,
    href,
    ignoreStoreCode,
    onClick,
    prefetch,
    ...restProps
}: ILinkProps): ReactElement => {
    const { startLoading } = useProgress(context);
    const { storeCode } = useRequestContext();

    const hrefString = href.toString();
    const { selectPromotion } = useSelectPromotion();

    if (hrefString.startsWith('https://')) {
        return (
            <a href={hrefString} {...restProps}>
                {children}
            </a>
        );
    }

    const preparedHref = (() => {
        const isHrefString = typeof href === 'string';
        const urlObj = new URL(isHrefString ? href : href.pathname || '', 'http://n.a');

        if (!ignoreStoreCode && !isPathnameWithStoreCode(urlObj.pathname)) {
            urlObj.pathname = addStoreCodeToPathname(urlObj.pathname, storeCode);
        }

        urlObj.pathname = removeLastSlashFromPathname(urlObj.pathname);

        return isHrefString ? urlObj.pathname + urlObj.hash + urlObj.search : { ...href, pathname: urlObj.pathname };
    })();

    const warmup = globalThis.navigator?.userAgent.includes('HH Cache-Warmer');

    return (
        <NextLink
            href={preparedHref}
            scroll={true}
            onClick={(e) => {
                onClick?.(e);
                if (hrefString.includes('promo_id')) {
                    selectPromotion({ promotion: hrefString });
                }
                startTransition(() => {
                    startLoading?.();
                });
            }}
            prefetch={warmup || (prefetch && typeof prefetch === 'boolean' ? prefetch : prefetch === 'true')}
            {...restProps}
        >
            {children}
        </NextLink>
    );
};

export default Link;
