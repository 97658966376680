import type { DocumentNode } from '@apollo/client';

type TMergeObject =
    | {
          [property: string]: string | DocumentNode;
      }
    | undefined;

const shallowMerge = (...args: TMergeObject[]) => Object.assign({}, ...args);

export default shallowMerge;
